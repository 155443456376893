import { PUBLIC_SENTRY_ENVIRONMENT } from '$env/static/public';
import * as Sentry from '@sentry/sveltekit';
import type { HandleClientError, HandleFetch } from '@sveltejs/kit';

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
	dsn: 'https://5e8a5e4dc7ea45c8f3c20b46f457a69c@o4506528300597248.ingest.sentry.io/4506533719703552',
	tracesSampleRate: 1,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1,
	integrations: [new Sentry.Replay()],
	environment: PUBLIC_SENTRY_ENVIRONMENT
});

export const handleError = Sentry.handleErrorWithSentry();
